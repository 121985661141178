import * as React from "react"
import * as styles from "./header.module.scss"
import { Link } from "gatsby"

import logo from "../../images/logoLight.png"
import PopoutMenu from "./PopoutMenu"

const Header = (): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.contentWrapper}>
      <Link className={styles.headerImage} to="/">
        <img className={styles.logo} src={logo} alt="" />
      </Link>
      <div className={styles.menuWrapper}>
        <PopoutMenu />
      </div>
    </div>
  </div>
)

export default Header
