import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import BasicPage from "../templates/BasicPage"
import BulkText from "../components/BulkText"

const htmlDump = (
  <>
    <p>
      Genie Delivery Limited is committed to protecting the privacy of all users
      of our website geniedelivery.co.uk, or mobile applications (together, the
      "Sites"). Please read the following privacy policy that explains how we
      use and protect your information. We are the "data controller" of the
      information we process, unless otherwise stated.
    </p>
    <h2>1. Contact Details</h2>
    <p>
      If you have any queries or requests concerning this privacy policy or how
      we handle your data more generally, please contact us at
      team@geniedelivery.co.uk.
    </p>
    <h2>2. How We Collect Your Information</h2>
    <p>
      We collect your personal information when you interact with us or use our
      services, such as when you use our Sites to place an order. We also look
      at how visitors use our Sites, to help us improve our services and
      optimise customer experience.
    </p>
    <p>We collect information:</p>
    <ol>
      <li>
        <p>
          When you create an account with us or you change your account
          settings;
        </p>
      </li>
      <li>
        <p>
          When you place an order with us and during the order process
          (including for payment and order delivery);
        </p>
      </li>
      <li>
        <p>
          When you give us your consent to contact you via email, phone, post,
          message or via our chat function to send you marketing campaigns, or
          to invite you to participate in surveys about our services, or our
          partners&rsquo; services;
        </p>
      </li>
      <li>
        <p>
          When you contact us directly via email, phone, post, message or via
          any chat function; and
        </p>
      </li>
      <li>
        <p>
          When you browse and use our Sites (before and after you create an
          account with us).
        </p>
      </li>
    </ol>
    <p>
      We also collect information from third party sites, such as advertising
      platforms and our fraud detection provider.
    </p>
    <h2>3. Information We Collect From You</h2>
    <p>
      As part of our commitment to the privacy of our customers and visitors to
      our Sites more generally, we want to be clear about the sorts of
      information we will collect from you.
    </p>
    <p>
      When you visit the Sites or make a Genie Delivery order through the Sites,
      you may be asked to provide information about yourself including your
      name, contact details, delivery address, order details and payment
      information such as credit or debit card information. We may also collect
      your date of birth to verify your age when you purchase age restricted
      items.
    </p>
    <p>
      We also collect information about your usage of the Sites and information
      about you from any messages you post to the Sites or when you contact us
      or provide us with feedback, including via e-mail, letter, phone or chat
      function. If you contact us by phone, we may record the call for training
      and service improvement purposes.
    </p>
    <p>
      We collect technical information from your mobile device or computer, such
      as its operating system, the device and connection type and the IP address
      from which you are accessing our Sites.
    </p>
    <p>
      We also collect technical information about your use of our services
      through a mobile device, for example, carrier, location data and
      performance data such as mobile payment methods, interaction with other
      retail technology such as use of NFC Tags, QR Codes and/or use of mobile
      vouchers. Unless you have elected to remain anonymous through your device
      and/or platform settings, this information may be collected and used by us
      automatically if you use the service through your mobile device(s) via any
      Genie Delivery mobile application, through your mobile's browser or
      otherwise.
    </p>
    <p>
      We process health information about you only where you volunteer and
      consent to this, for example if you specify any food allergies.
    </p>
    <h2>4. Use of Your Information</h2>
    <p>
      We will only process the data we collect about you if there is a reason
      for doing so, and if that reason is permitted under data protection law.
    </p>
    <p>
      Where we need to in order to provide you with the service you have
      requested or to enter into a contract, we use your information:
    </p>
    <ul>
      <li>
        <p>
          to enable us to provide you with access to the relevant parts of the
          Sites;
        </p>
      </li>
      <li>
        <p>to supply the services you have requested;</p>
      </li>
      <li>
        <p>to enable us to collect payment from you; and</p>
      </li>
      <li>
        <p>
          to contact you where necessary concerning our services, such as to
          resolve issues you may have with your order.
        </p>
      </li>
    </ul>
    <p>
      We also process your data where we have a legitimate interest for doing
      so&mdash; for example personalisation of our service, including processing
      data to make it easier and faster for you to place orders. The reasons for
      this include:
    </p>
    <ul>
      <li>
        <p>
          to improve the effectiveness and quality of service that our customers
          can expect from us in the future;
        </p>
      </li>
      <li>
        <p>
          to tailor content that we or our partner stores or advertising
          partners display to you, for example so that we can show item
          inventory in your area or make sure you see the advertising which is
          most relevant to you, based on characteristics determined by us;
        </p>
      </li>
      <li>
        <p>
          to enable our customer support team to help you with any enquiries or
          complaints in the most efficient way possible and to provide a
          positive customer experience;
        </p>
      </li>
      <li>
        <p>
          to contact you for your views and feedback on our services or our
          partners&rsquo; services and/or products and to notify you if there
          are any important changes or developments to the Sites or our
          services, including letting you know that our services are operating
          in a new area, where you have asked us to do so;
        </p>
      </li>
      <li>
        <p>
          to send you information by post about our products, services and
          promotions (if you do not want to receive these, you can let us know
          by getting in touch (see Contact Details));
        </p>
      </li>
      <li>
        <p>
          to analyse your activity on the Sites so that we can administer,
          support, improve and develop our business and for statistical and
          analytical purposes and to help us to prevent fraud.
        </p>
      </li>
    </ul>
    <p>
      We also process your data to enforce our contractual terms with you and
      any other agreement, and for the exercise or defence of legal claims and
      to protect the rights of Genie Delivery, partner stores, riders, or others
      (including to prevent fraud).
    </p>
    <p>
      If you submit comments and feedback regarding the Sites and the services,
      we may use such comments and feedback on the Sites and in any marketing or
      advertising materials. We will only identify you for this purpose by your
      first name and the city in which you live. Where you have chosen to
      receive push notifications from us through our mobile application, we may
      send you push notifications relating to the services that you have
      requested from us and information about our services and offers. You can
      choose to stop receiving push notifications from us at any time by
      changing your preferences on your mobile device or by getting in touch
      (see Contact Details).
    </p>
    <p>
      We will also analyse data about your use of our services from your
      location data to create profiles relating to you and for you. This means
      that we may make certain assumptions about what you may be interested in
      and use this, for example, to send you more tailored marketing
      communications, to prioritize items that we think you will prefer, or to
      let you know about special offers or products which we think you may be
      interested in. This activity is referred to as profiling. You have certain
      rights in relation to this type of processing. Please see 'Your Rights'
      section below for more information.
    </p>
    <p>
      Where we rely on legitimate interest as a basis for processing your
      personal information, we carry out a &lsquo;balancing test&rsquo; to
      ensure that our processing is necessary and that your fundamental rights
      of privacy are not outweighed by our legitimate interests. You can find
      out more information about these balancing tests by contacting us using
      the details above.
    </p>
    <p>
      Where we are under a legal obligation to do so we may use your information
      to create a record of your order(s) and comply with any legal obligation
      or regulatory requirement to which we are subject.
    </p>
    <h2>5. Cookies</h2>
    <p>
      <span lang="en-GB">
        At Genie Delivery we use cookies and other similar technologies like
        pixels and tags to allow our website to work, to analyse and improve it,
        to personalise your experience and to show you relevant advertising.
        Here&rsquo;s a breakdown of what that means for you.
      </span>
    </p>
    <p>
      <span lang="en-GB">
        <strong>What are cookies?</strong>
      </span>
    </p>
    <p>
      <span lang="en-GB">
        Cookies are pretty cool. They make your online experience personalised
        by helping us show you relevant Genie Delivery adverts when you browse
        other websites, helping us to understand how you are using our own
        website,
      </span>
      <span lang="en-GB">
        and saving you lots of time by remembering your details. But how do they
        do it?
      </span>
    </p>
    <p>
      <span lang="en-GB">
        Cookies are actually small files that are stored on your computer or
        phone. They store small bits of data so that when you visit a website,
        it can remember your preferences and make sure it shows you content that
        is relevant to you.
      </span>
    </p>
    <p>
      <span lang="en-GB">
        All cookies have expiry dates that determine how long they stay in your
        browser:
      </span>
    </p>
    <ul>
      <li>
        <p>
          <span lang="en-GB">Session cookies</span>
          <span lang="en-GB">
            are temporary cookies that expire automatically whenever you close
            your browser.
          </span>
        </p>
      </li>
      <li>
        <p>
          <span lang="en-GB">
            Persistent cookies usually stay in your browser for a set period, or
            until you manually delete them
          </span>
        </p>
      </li>
    </ul>
    <p>
      <span lang="en-GB">
        <strong>Turning off cookies</strong>
      </span>
    </p>
    <p>
      <span lang="en-GB">
        If you turn off all cookies, Genie Delivery won&rsquo;t work properly.
        But if you want to, you can delete your cookies and manage how cookies
        are used for the browser you use. Here are the instructions for desktop
        browsers:
      </span>
    </p>
    <ul>
      <li>
        <p>
          <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop">
            <span lang="en-GB">Google Chrome</span>
          </a>
        </p>
      </li>
      <li>
        <p>
          <a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox">
            <span lang="en-GB">Firefox</span>
          </a>
        </p>
      </li>
      <li>
        <p>
          <a href="https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
            <span lang="en-GB">Safari</span>
          </a>
        </p>
      </li>
      <li>
        <p>
          <a href="https://support.microsoft.com/help/17442/windows-internet-explorer-delete-manage-cookies">
            <span lang="en-GB">Internet Explorer&nbsp;</span>
          </a>
        </p>
      </li>
    </ul>
    <p>
      <span lang="en-GB">
        And here are the instructions for mobile browsers:
      </span>
    </p>
    <ul>
      <li>
        <p>
          <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid">
            <span lang="en-GB">Google Chrome on Android</span>
          </a>
        </p>
      </li>
      <li>
        <p>
          <a href="https://support.mozilla.org/en-US/kb/clear-your-browsing-history-and-other-personal-data">
            <span lang="en-GB">Firefox on Android</span>
          </a>
        </p>
      </li>
      <li>
        <p>
          <a href="https://support.apple.com/en-gb/HT201265">
            <span lang="en-GB">Safari on iOS</span>
          </a>
        </p>
      </li>
    </ul>
    <p>
      <span lang="en-GB">
        To opt out of being tracked by Google Analytics across all websites,
        visit http://tools.google.com/dlpage/gaoptout.
      </span>
    </p>
    <p>
      <span lang="en-GB">
        <strong>Necessary Cookies</strong>
      </span>
    </p>
    <p>
      <span lang="en-GB">
        Without cookies most websites wouldn&rsquo;t work, that includes Genie
        Delivery. The cookies necessary for Genie Delivery to work allow us to:
      </span>
    </p>
    <ul>
      <li>
        <p>
          <span lang="en-GB">
            Add items to your basket and create an order;
          </span>
        </p>
      </li>
      <li>
        <p>
          <span lang="en-GB">Check your order status;</span>
        </p>
      </li>
      <li>
        <p>
          <span lang="en-GB">
            Enable customer service agents to chat with you;
          </span>
        </p>
      </li>
      <li>
        <p>
          <span lang="en-GB">
            Keep records of your consent to our use of cookies.
          </span>
        </p>
      </li>
    </ul>
    <p>
      <span lang="en-GB">
        Our payment provider Stripe also uses cookies which are necessary to
        remember your card details and process your payments, without storing
        your card details on Genie Delivery&rsquo;s systems.{" "}
      </span>
    </p>
    <p>
      <span lang="en-GB">
        <strong>Analytics cookies</strong>
      </span>
    </p>
    <p>
      <span lang="en-GB">
        Analytics cookies help us understand how people are using our website.
        This helps us to constantly improve things.
      </span>
      <span lang="en-GB">The analytics cookies we use allow us to see:</span>
    </p>
    <ul>
      <li>
        <p>
          <span lang="en-GB">
            if you&rsquo;ve visited Genie Delivery before;
          </span>
        </p>
      </li>
      <li>
        <p>
          <span lang="en-GB">
            who&rsquo;s visited our website without being logged into an account
            &mdash; this tells us how many people are new to Genie Delivery;
          </span>
        </p>
      </li>
      <li>
        <p>
          <span lang="en-GB">
            how much time people spend on Genie Delivery and when they close
            their browser;
          </span>
        </p>
      </li>
      <li>
        <p>
          <span lang="en-GB">
            how our customers got to Genie Delivery &mdash; for example, if they
            came to our website from a Google search;
          </span>
        </p>
      </li>
      <li>
        <p>
          <span lang="en-GB">
            which parts of our website are being used and which parts
            aren&rsquo;t.
          </span>
        </p>
      </li>
    </ul>
    <p>
      <span lang="en-GB">
        <strong>Cookies to improve the content you see</strong>
      </span>
    </p>
    <p>
      <span lang="en-GB">
        We&rsquo;re also always testing out new ways of making Genie Delivery as
        good as it can be. We do this by trying out new ideas with small groups
        of customers before rolling things out to everybody. In order to do this
        we need to use cookies to allow us to:
      </span>
    </p>
    <ul>
      <li>
        <p>
          <span lang="en-GB">
            know which versions of our website you are visiting
          </span>
        </p>
      </li>
      <li>
        <p>
          <span lang="en-GB">
            control which versions you visit &mdash; to find out which ones work
            best
          </span>
        </p>
      </li>
    </ul>
    <p>
      <span lang="en-GB">
        <strong>Advertising cookies</strong>
      </span>
    </p>
    <p>
      <span lang="en-GB">
        We use third party cookies and pixels to collect data about your
        browsing activity on our website. This means we can show you relevant
        Genie Delivery adverts when you browse other websites. This might
        include ads for food types you&rsquo;ve ordered before &mdash; or ads
        showing you what it's like to be a Genie Delivery rider or explaining
        how to apply if you&rsquo;ve previously visited our rider apply page.
      </span>
    </p>
    <h2>6. Direct Marketing</h2>
    <p>
      Where you have given your consent or where we have a legitimate interest
      for doing so (and are permitted to do so by law) we will use your
      information to let you know about our other products and services that may
      be of interest to you and we may contact you to do so by email, post or
      phone.
    </p>
    <p>
      <strong>7. Automated Decision Making</strong>
    </p>
    <p>
      We may conduct fraud checks on our customers. Where we believe we may
      detect fraudulent activity we may block you from placing an order and
      using our Sites.
    </p>
    <p>
      We undertake fraud checks on all customers because this is necessary for
      us to perform our contracted services to customers, by ensuring that the
      services we provide are duly paid for, and also so that individuals
      themselves are protected from fraudulent transactions on their cards.
    </p>
    <p>
      You have certain rights in respect of this activity - please see 'Your
      Rights' section below for more information. Our fraud detection is in
      place to protect all of our customers as well as Genie Delivery. You have
      the right to contest any fraud decision made about you and to be given
      more information about why any such decision was made by contacting us
      (see Contact Details).
    </p>
    <h2>8. Retention Of Your Information</h2>
    <p>
      We will not retain your information for any longer than we think is
      necessary.
    </p>
    <p>
      Information that we collect will be retained for as long as needed to
      fulfil the purposes outlined in the &lsquo;Use of Your Information&rsquo;
      section above, in line with our legitimate interest or for a period
      specifically required by applicable regulations or laws, such as retaining
      the information for regulatory reporting purposes.
    </p>
    <p>
      When determining the relevant retention periods, we will take into account
      factors including:
    </p>
    <ul>
      <li>
        <p>
          our contractual obligations and rights in relation to the information
          involved;
        </p>
      </li>
      <li>
        <p>
          legal obligation(s) under applicable law to retain data for a certain
          period of time;
        </p>
      </li>
      <li>
        <p>statute of limitations under applicable law(s);</p>
      </li>
      <li>
        <p>
          our legitimate interests where we have carried out balancing tests
          (see section on 'How we use your personal information' above);
        </p>
      </li>
      <li>
        <p>(potential) disputes; and</p>
      </li>
      <li>
        <p>guidelines issued by relevant data protection authorities.</p>
      </li>
    </ul>
    <p>
      Otherwise, we securely erase your information where we no longer require
      your information for the purposes collected.
    </p>
    <h2>9. Disclosure Of Your Information</h2>
    <p>
      The information we collect about you will be transferred to and stored on
      our servers located within the EU. We are very careful and transparent
      about who else your information is shared with.
    </p>
    <p>
      We share your information with other Genie Delivery group companies only
      where necessary for the purposes set out in section 4. We share your
      information with third party service providers which provide services on
      our behalf. The types of third party service providers whom we share your
      information with include for example:
    </p>
    <ul>
      <li>
        <p>
          Payment providers (including online payment providers and fraud
          detection providers);
        </p>
      </li>
      <li>
        <p>IT service providers (including cloud providers);</p>
      </li>
      <li>
        <p>Partner stores;</p>
      </li>
      <li>
        <p>Riders;</p>
      </li>
      <li>
        <p>Customer support partners; and</p>
      </li>
      <li>
        <p>Marketing and advertising partners.</p>
      </li>
    </ul>
    <p>
      Genie Delivery will take all steps reasonably necessary to ensure that
      your data is treated securely and in accordance with this privacy policy
      when it is transferred to third parties.
    </p>
    <p>
      If our business enters into a joint venture with, purchases or is sold to
      or merged with another business entity, your information may be disclosed
      or transferred to the target company, our new business partners or owners
      or their advisors.
    </p>
    <p>We may also share your information:</p>
    <ul>
      <li>
        <p>
          if we are under a duty to disclose or share your information in order
          to comply with (and/or where we believe we are under a duty to comply
          with) any legal obligation or regulatory requirement. This includes
          exchanging information with other companies and other organisations
          for the purposes of fraud protection and prevention;
        </p>
      </li>
      <li>
        <p>
          in order to enforce our contractual terms with you and any other
          agreement;
        </p>
      </li>
      <li>
        <p>
          to protect the rights of Genie Delivery, partner stores, riders, or
          others, including to prevent fraud; and
        </p>
      </li>
      <li>
        <p>
          with such third parties as we reasonably consider necessary in order
          to prevent crime, e.g. the police or for health and safety purposes.
        </p>
      </li>
    </ul>
    <h2>10. Security</h2>
    <p>
      We adopt robust technologies and policies to ensure the personal
      information we hold about you is suitably protected.
    </p>
    <p>
      We take steps to protect your information from unauthorised access and
      against unlawful processing, accidental loss, destruction and damage.
    </p>
    <p>
      Where you have chosen a password that allows you to access certain parts
      of the Sites, you are responsible for keeping this password confidential.
      We advise you not to share your password with anyone.
    </p>
    <p>
      Unfortunately, the transmission of information via the internet is not
      completely secure. Although we will take steps to protect your
      information, we cannot guarantee the security of your data transmitted to
      the Sites; any transmission is at your own risk. Once we have received
      your information, we will use strict procedures and security features to
      try to prevent unauthorised access.
    </p>
    <h2>11. Your Rights</h2>
    <p>
      Subject to applicable law, you may have a number of rights concerning the
      data we hold about you. If you wish to exercise any of these rights,
      please contact us using the contact details set out above. For additional
      information on your rights please contact your data protection authority
      and see below.
    </p>
    <p>
      To the extent provided by the law of your jurisdiction, you may request
      access to the personal information we maintain about you or request that
      we correct, update, amend, or delete your information, or that we restrict
      the processing of such information by contacting us as indicated below.
    </p>
    <p>
      You may have the right to obtain your personal information in an
      accessible and transferable format so that you can re-use it for your own
      purposes across different service providers.
    </p>
    <p>
      Where provided by law, you may withdraw any consent you previously
      provided to us or object at any time on legitimate grounds to the
      processing of your personal information, and we will apply your
      preferences going forward. This will not affect the lawfulness of our use
      of your information based on your consent before its withdrawal.
    </p>
    <p>
      You can object by changing your marketing preferences, disabling cookies
      as set out in sections 5 and 6 above or by getting in touch (see Contact
      Details).
    </p>
    <h2>13. Changes To Our Privacy Policy</h2>
    <p>
      Any changes to our privacy policy will be posted to the Sites and, where
      appropriate, we may notify you of the changes for example by email or push
      notification.
    </p>
    <p>This privacy policy was last updated: 25/09/2020</p>
    <p>
      <strong>14. How to Contact the Appropriate Authority</strong>
    </p>
    <p>
      If you&rsquo;re not satisfied with our response to any complaint or
      believe our processing of your information does not comply with data
      protection law, you can make a complaint to the Information
      Commissioner&rsquo;s Office (ICO) using the following details:
    </p>
    <p>
      Address: Information Commissioner&rsquo;s Office, Wycliffe House, Water
      Lane, Wilmslow, Cheshire SK9 5AF
    </p>
    <p>
      Website:&nbsp;<a href="https://www.ico.org.uk/">www.ico.org.uk</a>
    </p>
  </>
)

const PrivacyPolicyPage = (): JSX.Element => (
  <Layout>
    <SEO
      title="Privacy Policy"
      description="Genie is committed to protecting the privacy of all users of our products and services. Please read the following privacy policy that explains how we use and protect your information."
    />
    <BasicPage>
      <BulkText content={htmlDump} />
    </BasicPage>
  </Layout>
)

export default PrivacyPolicyPage
